import { useState, useEffect, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { useTexture } from '@react-three/drei'
import * as THREE from 'three'

export default function Phyllotaxis({spiralBase=2})
{
    const [ nodeCount ] = useState(15000)
    const phyllo = useRef()
    const phylloGroup = useRef()
    const [ angleSquare ] = useState(spiralBase)
    const [ smoothedCameraPos ] = useState(() => new THREE.Vector3(0, 0, 50))
    
    const material = useTexture('./GreenMatCap256.png')
    
    useEffect(() =>
    {
        //Phyllotaxis
        const baseAngle = Math.PI * (1 + Math.sqrt(angleSquare))
        for (let i = 0; i < nodeCount; i++)
        {
            const radius = Math.sqrt(i) * 1.5
            const theta = i * baseAngle
            const xPos = radius * Math.cos(theta)
            const yPos = radius * Math.sin(theta)
            const matrix = new THREE.Matrix4()
            matrix.compose(
                new THREE.Vector3(xPos, yPos, (i * 0.01)),
                new THREE.Quaternion(),
                new THREE.Vector3(1,1,1)
            )
            phyllo.current.setMatrixAt(i, matrix)
        }
        phyllo.current.instanceMatrix.needsUpdate = true
    }, [])

    useFrame((state, delta) => 
    {
        const time = state.clock.getElapsedTime()
        const camera = state.camera
        phylloGroup.current.rotation.z = time * 0.05
        const cameraPos =  new THREE.Vector3(
            Math.cos(time * 0.01) * 50,
            Math.sin(time * 0.01) * 50,
            50,
            
        )
        smoothedCameraPos.lerp(cameraPos, delta * 0.05)
        camera.position.copy(smoothedCameraPos)
        camera.lookAt(0, 0, 0)
    })

    return <group ref={phylloGroup}>
            <instancedMesh ref={phyllo} args={[null, null, nodeCount]}>
                <sphereGeometry args={[0.8, 12, 8]} smooth />
                <meshMatcapMaterial matcap={material} />
            </instancedMesh>
        </group>
}