import { useEffect, useState } from "react";

export default function useFetch(dataSource)
{
    const [data, setData] = useState([])

    const fetchData = () => 
    {
        fetch(dataSource)
        .then(response => response.json())
        .then(json => setData(json))
    }

    useEffect(() =>
    {
        fetchData(data)        
    }, [dataSource])

    return [data]
}