import { useEffect, useState, createElement } from "react"
import { ListingCard, SimpleLinkCard, SimpleCard } from "./Cards.jsx"
import useFetch from './useFetch.jsx'

export default function AboutPage()
{
    const [pageData] = useFetch('./aboutPage.json')

    function renderPage() 
    {
        return <>
            {
                pageData.map((item) => {
                    if (item.divClass == "simpleLinkCard") {
                        return <SimpleLinkCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "simpleCard") {
                        return <SimpleCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "listingCard") {
                        return <ListingCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "heading") {
                        return <h1 key={item.id}>{item.desc}</h1>
                    }
                })
            }
        </>
    }

    return <div className="pageContent">
        {pageData && ( renderPage() )}
    </div>
}