import { useState, ReactComponentElement } from 'react'
import './App.css'
import Navigation from './Navigation.jsx'
import HomePage from './HomePage.jsx'
import AboutPage from './AboutPage.jsx'
import ArticlesPage from './ArticlesPage.jsx'
import ProjectsPage from './ProjectsPage.jsx'

export default function App() {
  const [currentPage, setCurrentPage ] = useState('#Home')
  
  return <div className='siteContainer'>
        <Navigation setCurrentPage={setCurrentPage} />
        {currentPage === '#Home' && <HomePage />}
        {currentPage === '#About' && <AboutPage />}
        {currentPage === '#Articles' && <ArticlesPage />}
        {currentPage === '#Projects' && <ProjectsPage />}
      </div>
}

