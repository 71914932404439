import { useEffect, useState } from "react"
import Logo from "./Logo"

export default function Navigation({setCurrentPage})
{
    const [menuData, setMenuData] = useState([])

    const fetchMenuData = () =>
    {
        fetch('./menuItems.json')
            .then((response) =>
			{
				return response.json()
			} )
			.then((data) => 
			{
				setMenuData(data)
			})
    }

	useEffect(() => {
		fetchMenuData()
	}, [])

	function menuSetter(newPage)
	{
		setCurrentPage(newPage)
	}

    return <>
		<div className="navigationBar">
				<div className="navLeft">
					<p onClick={() => menuSetter('#Home')}>Welcome</p>
					<p onClick={() => menuSetter('#About')}>About</p>
				</div>
				<div className="logo" onClick={() => menuSetter('#Hide')}>
					<Logo />
				</div>
				<div className="navRight">
					<p onClick={() => menuSetter('#Articles')}>Articles</p>
					<p onClick={() => menuSetter('#Projects')}>Projects</p>
				</div>
		</div>
    </>
}