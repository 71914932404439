import { useEffect, useState, createElement } from "react"
import { ListingCard, SimpleLinkCard, SimpleCard } from "./Cards.jsx"
import useFetch from './useFetch.jsx'

export default function ArticlesPage()
{
    const [pageData] = useFetch('./articlesPage.json')

    function renderPage() 
    {
        return <>
            {
                pageData.map((item) => {
                    if (item.divClass == "simpleLinkCard") {
                        return <SimpleLinkCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "simpleCard") {
                        return <SimpleCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "listingCard") {
                        return <ListingCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "heading") {
                        return <h1 key={item.id}>{item.desc}</h1>
                    }
                })
            }
        </>
    }

    return <div className="pageContent">
        <p>Articles coming soon(ish)! As I mentioned on the home page, I'm currently in my final year of college so 
            writing articles is going to take a backseat to completeing school assignments. While some of those assignments
            would make excellent articles in their own right I'm refraining from posting them in the interest of academic integrity.
        </p>
        <p>Thank you for your understanding</p>
        {pageData && ( renderPage() )}
    </div>
}