import { useEffect, useState } from "react"
import useFetch from './useFetch.jsx'

export default function HomePage()
{
    const [homePageData] = useFetch('./homePage.json')

    function renderPage() {
        return <>
            <h1>Hello World!</h1>
            <p>... and welcome to the Oudio Visual Department. The personal website/portfolio of Kris Oud. 
                It's a pleasure to have you here =]</p>
            <p>Through this site I'd like to share some of my interests and hobbies... show a few projects... you know, 
                the kind of thing one does on a portfolio site. Currently, I'm in my final year of college for Software 
                Engineering; so that, along with working full time, means content will be trickling out for the next
                little while as free time allows.</p>
            <p>So what are my hobbies? Well...</p>
            <ul>
                <li>Programming</li>
                <li>Electronics</li>
                <li>Woodworking</li>
                <li>Design and Making along with the additive and subtractive processes that make it possible</li>
                <li>Math, Computer Science, Non-Computer Science</li>
                <li>...and other such nerdy things</li>
            </ul>
            <div className="siteCredits">
                <p>This site is powered by React & React Three Fiber</p>
                <p>Fonts used under the Open Font License:</p>
                <ul>
                    <li>Press Start 2P by <a href="https://fonts.google.com/specimen/Press+Start+2P/about?query=press+start+2p" target="_blank">CodeMan38</a></li>
                    <li>PT Mono by <a href="https://fonts.google.com/specimen/PT+Mono/about?query=PT+Mono" target="_blank">ParaType</a></li>
                </ul>
                <p>Thank you!</p>
            </div>
        </>
    }

    return <div className="pageContent">
        {homePageData && ( renderPage() )}
    </div>
}