export function SimpleCard({props})
{
    return <div className="cardBody">
        <div className="cardHead">
            <h2 className="title">{props.title}</h2>
            <h3 className="dates">{props.dates}</h3>
        </div>
        <div className="content">
            <p>{props.desc}</p>
        </div>
    </div>
}

export function SimpleLinkCard({props})
{
    return <div className="cardBody">
        <div className="cardHead">
            <h2 className="title">{props.title}</h2>
            <h3 className="dates">{props.dates}</h3>
        </div>
        <div className="content">
            <p>{props.desc}</p>
            <a href={props.link} target="_blank">{props.linkText}</a>
        </div>
    </div>
}

export function ListingCard({props})
{
    return <div className="cardBody">
        <div className="cardHead">
            <h2 className="title">{props.title}</h2>
            <h3 className="dates">{props.dates}</h3>
        </div>
        <div className="content">
            <p>{props.desc}</p>
            <ul>
            {
                props.list.map((listItem) => 
                {
                    return <li key={listItem.id}>{listItem.value}</li>
                })
            }
            </ul>
        </div> 
    </div>
}

export function SimpleProjectCard({props})
{
    return <div className="cardBody">
        <div className="cardHead">
            <h2 className="title">{props.title}</h2>
            <h3 className="dates">{props.dates}</h3>
        </div>
        <div className="content">
            <p>{props.desc}</p>
            <p><a href={props.link} >{props.linkText}</a></p>
            <p><a href={props.gitLink} target="_blank">{props.gitLinkText}</a></p>

        </div>
    </div>
}