import { useEffect, useState, createElement } from "react"
import { ListingCard, SimpleLinkCard, SimpleCard, SimpleProjectCard } from "./Cards.jsx"
import useFetch from './useFetch.jsx'

export default function ProjectsPage()
{
    const [pageData] = useFetch('./projectsPage.json')

    function renderPage() 
    {
        return <>
            {
                pageData.map((item) => {
                    if (item.divClass == "simpleLinkCard") {
                        return <SimpleLinkCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "simpleCard") {
                        return <SimpleCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "listingCard") {
                        return <ListingCard key={item.id} props={item.props}/>
                    } else if (item.divClass == "simpleProjectCard") {
                        return <SimpleProjectCard key={item.id} props={item.props}/>
                    }else if (item.divClass == "heading") {
                        return <h1 key={item.id}>{item.desc}</h1>
                    }
                })
            }
        </>
    }

    return <div className="pageContent">

        <div className="cardBody">
            <div className="cardHead">
                <h2 className="title">Little Man Computer</h2>
            </div>
            <div className="content">
                <div className="imageGrid">
                    <img src="./projectImgs/LittleManComputer.PNG" />
                    <p>
                        A recreation of the Little Man Computer created by Dr. Stuart Madnick in 1965. This project
                        is built using tailwindCSS and vanilla JavaScript.
                        <br /><br />
                        Paper computers were used to teach a generation of engineers and programmers the basic theory of 
                        how computers operate. 
                        <br /><br />
                        Inspired by Chris Staecker's&nbsp;
                        <a href="https://www.youtube.com/watch?v=t-mOfXhgfQY&t=715s" target="_blank">YouTube Video</a>
                    </p>
                    <div className="linksBar">
                        <a href="https://oudiovisual.ca/littleman/">Try it Here</a>
                        <a href="https://github.com/MrOud/LittleManComputer">Github Repo</a>
                    </div>
                </div> 
            </div>
        </div>

         <div className="cardBody">
            <div className="cardHead">
                <h2 className="title">Node/Express Portfolio website</h2>
            </div>
            <div className="content">
                <div className="imageGrid">
                    <img src="./projectImgs/expressPortfolio.PNG" />
                    <p>
                        The first assignment in my web applications class was to make a portfolio site. This site also makes use of
                        SVGs to provide background animations to enhance the experience. As this code is part of an ongoing class the 
                        github repo is still private, but will be made public after the course in completed. 
                        <br></br><br></br>
                        Please note, this site is hosted on 
                        render.com's free tier currently which means the server will go to sleep after 15 minutes of inactivity; as such the initial
                        load will take a few moments as the server wakes up.
                    </p>
                    <div className="linksBar">
                        <a href="https://comp229-assignment1-zwqy.onrender.com/">View Here</a>
                    </div>
                </div> 
            </div>
        </div>

        <div className="cardBody">
            <div className="cardHead">
                <h2 className="title">Brick Breaker</h2>
                <h3 className="dates">V0.01a</h3>
            </div>
            <div className="content">
                <div className="imageGrid">
                    <img src="./projectImgs/brickBreaker.PNG" />
                    <p>
                        A classic Brickbreaker game made using React 3 Fiber. This game is currently in development and you can
                        follow the dev logs in the articles as I continue to improve on this game. For now the controls are keyboard
                        only, however mobile support will be added in the future. As I am currently in my final year of school, work on this
                        project is a secondary priority.
                    </p>
                    <div className="linksBar">
                        <a href="https://oudiovisual.ca/projects/brickBreaker/">Play Here</a>
                        <a href="https://github.com/MrOud/BrickBreaker">Github Repo</a>
                    </div>
                </div> 
            </div>
        </div>

        <div className="cardBody">
            <div className="cardHead">
                <h2 className="title">8mm Film Digitizer</h2>
            </div>
            <div className="content">
                <div className="imageGrid">
                    <img src="./projectImgs/digitizer.jpg" />
                    <p>
                        In the mid 2010's I worked in a photo studio where one of our specialties was preserving older formats
                        to be enjoyed on modern equipment. Combining my love of preserving history with my excitiment for working
                        with hardware I created a digitizer to transfer 8mm film to DVD.
                    </p>
                    <div className="linksBar">
                        <a href="https://oudiovisual.ca/projects/8mmDigitizer/">Read more details here</a>
                    </div>
                </div> 
            </div>
        </div>

        <div className="cardBody">
            <div className="cardHead">
                <h2 className="title">Cannon Demo</h2>
            </div>
            <div className="content">
                <div className="imageGrid">
                    <img src="./projectImgs/cannon.PNG" />
                    <p>
                        A small project made to reinforce my learning mid-way through the Three.js Journey course. As much
                        as following tutorials provides valuable learning, taking time to go beyond tutorials and complete
                        small projects is also a vitally important part of the learning process. 
                        <br></br><br></br>
                        Control is for keyboard only.
                    </p>
                    <div className="linksBar">
                        <a href="https://oudiovisual.ca/threeCannon/">Play Here</a>
                        <a href="https://github.com/MrOud/ThreeJSCannon">Github Repo</a>
                    </div>
                </div> 
            </div>
        </div>

    </div>
}